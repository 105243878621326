<template>
  <page-title
    pageTitle="Customer Price Group"
    title="Edit Price By Product Group"
  />
  <customer-product-group-item-form
    :priceGroup="{}"
    action-type="edit"
    :submitFunction="editCustomersProductGroupItems"
    :disabled-list="new Set(['barcode', 'costPrice', 'retailPrice'])"
  />
</template>

<script>
import PageTitleComponent from "@/components/shared/PageTitle";
import CustomerProductGroupItemForm from "@/components/priceGroup/CustomerProductGroupItemForm";

export default {
  name: "EditCustomerProductGroupView",
  components: {
    CustomerProductGroupItemForm,
    PageTitle: PageTitleComponent,
  },
  data() {
    return {
      id: -1,
      priceGroup: null,
    };
  },
  computed: {
    error() {
      return this.$store.getters.billersError;
    },
    loading() {
      return this.$store.getters.billersLoading;
    },
    priceGroupName() {
      if (this.$store.getters.getPriceGroupByID(this.id)) {
        return this.$store.getters.getPriceGroupByID(this.id).name;
      }
      return "Customer Price Group";
    },
  },
  methods: {
    async editCustomersProductGroupItems(productGroup) {
      await this.$store.dispatch("editPriceGroupItem", productGroup);
      this.$router.push(`/priceGroups/${productGroup.priceGroupID}`);
    },
  },
  async created() {
    this.id = parseInt(this.$route.params.id);
    let filter = {
      resultsPerPage: 1,
    };
    await this.$store.dispatch("getPriceGroups", filter);
  },
};
</script>

<style scoped></style>
