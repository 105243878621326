<template>
  <dialogue-modal
    v-if="billerToDelete"
    title="Are you sure you want to send it to recovery?"
    :message="billerToDelete.company"
    :close-popup="hideDeleteModal"
    :buttons="buttons"
  />
  <page-title pageTitle="Billers" title="All Billers" />

  <div class="row pb-4 gy-3">
    <div class="col-sm-4">
      <router-link to="/billers/new" class="btn btn-primary addtax-modal">
        <i class="las la-plus me-1"></i> Add New
      </router-link>
    </div>

    <div class="col-sm-auto ms-auto">
      <div class="d-flex gap-3">
        <div class="search-box">
          <input
            type="text"
            class="form-control"
            id="searchMemberList"
            placeholder="Search for Result"
            v-model="searchQuery"
            @input="goToPage(1)"
          />
          <i class="las la-search search-icon"></i>
        </div>
      </div>
    </div>

    <div class="col-sm-auto">
      <div class="d-flex input-group">
        <label class="input-group-text" for="inputGroupSelect01">Show</label>
        <select
          class="form-select"
          id="resultsPerPage"
          v-model="resultsPerPage"
          @change="goToPage(1)"
        >
          <option
            v-for="option in resultsPerPageOptions"
            :key="option"
            :value="option"
          >
            {{ option }}
          </option>
        </select>
      </div>
    </div>
  </div>

  <div v-if="data.length > 0" class="">
    <div class="row">
      <div class="col-xl-12">
        <div class="card">
          <div class="card-body">
            <table-component
              :headers="headers"
              :data="data"
              :actions="actions"
              :offset="(currentPage - 1) * resultsPerPage"
              :recoverable="true"
              @sort="goToPage"
            />
          </div>
        </div>
      </div>
    </div>
    <pagination-component
      :rows-loaded="data.length"
      :current-page="currentPage"
      :num-pages="numPages"
      :total-rows="totalRows"
      :go-to-page="goToPage"
    />
  </div>

  <div v-else class="row">
    <div class="col-xl-12">
      <div class="card">
        <LoadingModal v-if="loading" />
        <div v-else class="card-body">
          {{ error }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageTitleComponent from "@/components/shared/PageTitle";
import TableComponent from "@/components/utils/Table";
import { SETTINGS } from "@/constants/config.js";
import { CONSTANTS } from "@/constants/constants.js";
import LoadingModalComponent from "@/components/utils/LoadingModal";
import PaginationComponent from "@/components/utils/paginationComponent";
import DialogueModal from "@/components/utils/modals/DialogueModal";

export default {
  name: "AllBillersView",
  components: {
    DialogueModal,
    PaginationComponent,
    LoadingModal: LoadingModalComponent,
    PageTitle: PageTitleComponent,
    TableComponent: TableComponent,
  },
  data() {
    return {
      searchQuery: "",
      resultsPerPage: SETTINGS.resultsPerPage,
      billerToDelete: null,
    };
  },
  computed: {
    headers() {
      return CONSTANTS.BILLER_HEADERS;
    },
    data() {
      return this.$store.getters.allBillers;
    },
    currentPage() {
      return this.$store.getters.billersCurrentPage;
    },
    numPages() {
      return this.$store.getters.billersNumPages;
    },
    totalRows() {
      return this.$store.getters.billersTotalRows;
    },
    resultsPerPageOptions() {
      const options = CONSTANTS.RESULTS_PER_PAGE_OPTIONS;
      if (!options.includes(this.resultsPerPage)) {
        options.unshift(this.resultsPerPage);
      }
      return options;
    },
    actions() {
      return {
        View: (biller) => this.viewBiller(biller.id),
        Edit: (biller) => this.editBiller(biller.id),
        Delete: (biller) => this.showDeleteModal(biller),
      };
    },
    error() {
      return this.$store.getters.billersError;
    },
    loading() {
      return this.$store.getters.billersLoading;
    },
    buttons() {
      return {
        Yes: {
          class: "btn-danger",
          function: () => this.deleteBiller(),
        },
        No: {
          class: "btn-light px-3",
          function: () => this.hideDeleteModal(),
        },
      };
    },
  },
  methods: {
    async goToPage(page, sortBy = null) {
      let filter = {
        sortingList: sortBy,
        currentPage: page,
        resultsPerPage:
          this.resultsPerPage === "ALL" ? this.totalRows : this.resultsPerPage,
        searchQuery: this.searchQuery,
      };
      await this.$store.dispatch("getBillers", filter);
    },
    viewBiller(id) {
      this.$router.push("/billers/" + id);
    },
    editBiller(id) {
      this.$router.push("/billers/edit/" + id);
    },
    showDeleteModal(biller) {
      this.billerToDelete = biller;
    },
    async deleteBiller() {
      if (this.billerToDelete) {
        await this.$store.dispatch("deleteBiller", this.billerToDelete.id);
        await this.goToPage(1);
        this.hideDeleteModal();
      } else {
        alert("There has been an error");
      }
    },
    hideDeleteModal() {
      this.billerToDelete = null;
    },
  },
  async created() {
    let filter = {
      resultsPerPage: SETTINGS.resultsPerPage,
    };
    await this.$store.dispatch("getBillers", filter);
  },
  async beforeUnmount() {
    await this.$store.dispatch("resetBiller");
  },
};
</script>

<style scoped></style>
